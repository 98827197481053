<!-- 收益记录 -->

<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="main-wrap">
			<div class="card-list">
				<listItem2 v-for="(item, index) in tableData" :key="index" :itemData="item" :config="listItemConfig" @click="toDetail(item)">
					<div slot="custom-row" class="price-row">
						<span class="label">总费用</span>
						<span class="value">￥{{ item.amount || 0 }}</span>
					</div>
				</listItem2>
				<KcEmpty v-if="!tableData.length"></KcEmpty>
			</div>
		</div>
		<detailPopup ref="detailPopup"></detailPopup>
	</van-pull-refresh>
</template>

<script>
import listItem2 from '@components/listItem/listItem2';
import detailPopup from './detailPopup.vue';
export default {
	name: 'approveRecord',
	components: { listItem2, detailPopup },
	inject: ['recordData'],
	data() {
		return {
			isRefresh: false,

			listItemConfig: {
				titleKey: 'deviceName',
        imgKey:'photo',
        layout: [
					{
						label: this.$VUEAPPDEVICECODE(),
						key: 'code'
					},
					{
						label: '卡片号',
						key: 'label'
					},
					{
						label: '设备分类',
						key: 'categoryName'
					},
					{
						label: '品牌',
						key: 'brandName'
					},
					{
						label: '型号',
						key: 'modelName'
					}
				]
			},
			tableData: []
		};
	},
	created() {
		this.getList();
	},
	mounted() {
		this.$bus.on('workManagerRecordSearch', this.getList);
	},
	beforeDestroy() {
		this.$bus.off('workManagerRecordSearch', this.getList);
	},
	methods: {
		onRefresh() {
			this.isRefresh = true;
			this.getList(() => {
				setTimeout(() => {
					this.isRefresh = false;
				}, 300);
			});
		},
		getList(callbak) {
			this.$api.deviceWorkManager
				.orderList({
          isTenantIdChild:1,
          query: this.recordData.baseData.query,
					startTime: this.recordData.baseData.currentDate[0] + ' 00:00:00',
					endTime: this.recordData.baseData.currentDate[1] + ' 23:59:59',
					type: 4
				})
				.then(res => {
					this.tableData = res.data;
					if (typeof callbak == 'function') callbak();
				})
				.catch(() => {
					if (typeof callbak == 'function') callbak();
				});
		},
		toDetail(item) {
			// this.$push('/workManager/approveDetail');
			this.$refs.detailPopup.show(item);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
.price-row {
	display: flex;
	justify-content: flex-end;
	margin-top: 8px;
	font-size: 14px;
	line-height: 16px;
	.label {
		color: #999;
	}
	.value {
		color: #333;
		margin-left: 8px;
	}
}
</style>
