<template>
  <van-popup v-model="visible" round closeable position="bottom" get-container="body" :style="{ height: '80%' }">
    <div class="main-wrap-popup">
      <div class="main-header">
        <div class="title">收益详情</div>
      </div>
      <div class="main-container layout-1">
        <div class="row-price">
          <p class="label">总费用（元）</p>
          <p class="value">{{ amount }}</p>
        </div>

        <div class="row-3">
          <div class="title">{{ name }}</div>
          <div class="row-wrap">
            <div class="left-img"><img class="img"
                                       :src="image"
                                       alt=""
            /></div>
            <div class="right-list">
              <div class="list-item">
                <span class="label">{{$VUEAPPDEVICECODE()}}</span>
                <span class="value">{{ deviceCode }}</span>
              </div>
              <div class="list-item">
                <span class="label">卡片号</span>
                <span class="value">{{ label }}</span>
              </div>
              <div class="list-item">
                <span class="label">设备分类</span>
                <span class="value">{{ categoryName }}</span>
              </div>
              <div class="list-item">
                <span class="label">品牌</span>
                <span class="value">{{ brandName }}</span>
              </div>
              <div class="list-item">
                <span class="label">型号</span>
                <span class="value">{{ modelName }}</span>
              </div>
              <div class="list-item">
                <span class="label">审核时间</span>
                <span class="value">{{ approvalTime }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row-4">
          <div class="list-item">
            <span class="label">计费方式</span>
            <span class="value">{{ $formatBillingType1(dataInfo['billingType']).name }}</span>
          </div>
          <div class="list-item">
            <span class="label">开始使用时间</span>
            <span class="value">{{ startTime }}</span>
          </div>
          <div class="list-item">
            <span class="label">使用时长</span>
            <span class="value">{{ useTime }}</span>
          </div>
        </div>

      </div>
    </div>
  </van-popup>
</template>

<script>
import { formatStatusData } from '@/baseConfig/orderStatusOptions'
import { getTimeMsg } from '@utils/utils'

export default {
  name: 'detailPopup',
  components: {},
  data() {
    return {
      visible: false,
      dataInfo: {},
      rate: 2
    }
  },
  computed: {
    image() {
      if (this.$valueIsExist(this.dataInfo, 'photo') && this.dataInfo['photo'] != '') {
        let photo = this.dataInfo['photo']
        return `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}`
      }
      return this.$replacePhoto('')
    },
    amount() {
      if (this.$valueIsExist(this.dataInfo, 'amount') == false) {
        return '0'
      }
      return this.dataInfo['amount']
    },
    title() {
      let orderStatus = -1
      if (this.$valueIsExist(this.dataInfo, 'orderStatus')) {
        orderStatus = this.dataInfo['orderStatus']
      }
      return formatStatusData(orderStatus).label
    },
    estimatedAmount() {
      if (this.$valueIsExist(this.dataInfo, 'estimatedAmount') == false) {
        return '0'
      }
      return this.dataInfo['estimatedAmount']
    },
    billing() {
      if (this.$valueIsExist(this.dataInfo, 'billingType') == false) {
        return '次'
      }
      return this.formatBillingType(this.dataInfo['billingType'])
    },
    tenantName() {
      if (this.$valueIsExist(this.dataInfo, 'tenantName') == false) {
        return ''
      }
      return this.dataInfo['tenantName']
    },
    orderUser() {
      if (this.$valueIsExist(this.dataInfo, 'orderUser') == false) {
        return ''
      }
      return this.dataInfo['orderUser']
    },
    status() {
      let status = 0
      if (this.$valueIsExist(this.dataInfo, 'status')) {
        status = this.dataInfo['status']
      }
      return this.getStatus(status)
    },
    severity() {
      let severity = 0
      if (this.$valueIsExist(this.dataInfo, 'severity')) {
        severity = this.dataInfo['severity']
      }
      return this.getSeverityData(severity)
    },
    deviceCode() {
      if (this.$valueIsExist(this.dataInfo, 'code') == false) {
        return ''
      }
      return this.dataInfo['code']
    },
    modelName() {
      if (this.$valueIsExist(this.dataInfo, 'modelName') == false) {
        return ''
      }
      return this.dataInfo['modelName']
    },
    brandName() {
      if (this.$valueIsExist(this.dataInfo, 'brandName') == false) {
        return ''
      }
      return this.dataInfo['brandName']
    },
    categoryName() {
      if (this.$valueIsExist(this.dataInfo, 'categoryName') == false) {
        return ''
      }
      return this.dataInfo['categoryName']
    },
    name() {
      if (this.$valueIsExist(this.dataInfo, 'name') == false) {
        return ''
      }
      return this.dataInfo['name']
    },
    label() {
      if (this.$valueIsExist(this.dataInfo, 'label') == false) {
        return ''
      }
      return this.dataInfo['label']
    },
    price() {
      if (this.$valueIsExist(this.dataInfo, 'price') == false) {
        return '0'
      }
      return this.formatDate(this.dataInfo['price'])
    },
    useTime() {
      let useTime = 0
      if (this.$valueIsExist(this.dataInfo, 'useTime')) {
        useTime = this.dataInfo['useTime']
      }
      return getTimeMsg(useTime / 1000, 0)
    },
    startTime() {
      if (this.$valueIsExist(this.dataInfo, 'startTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['startTime'])
    },
    orderTime() {
      if (this.$valueIsExist(this.dataInfo, 'orderTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['orderTime'])
    },
    approvalTime() {
      if (this.$valueIsExist(this.dataInfo, 'approvalTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['approvalTime'])
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    show(info) {
      this.visible = true
      this.getDetail(info)
    },
    hide() {
      this.visible = false
    },
    getDetail(info) {
      this.$api.deviceWorkManager.orderDataInfo({
        id: info.id
      })
          .then(res => {
            this.dataInfo = res.data
          })
          .catch(() => {
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';

.main-wrap-popup {
  background: none;

  .main-header {
    .title {
      color: #333;
    }
  }

  .main-container {
    padding-top: 20px;

    .row-price {
      background: #3e73fb;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 12px;
      color: #fff;

      .label {
        font-size: 12px;
      }

      .value {
        font-size: 24px;
        margin-top: 8px;
      }
    }
  }
}
</style>
